<template>
  <WidgetFrame @onResize="onResize" v-bind:class="sizeClass">
    <template v-slot:title>
      Information
    </template>
    <template v-slot:editor>
      <a id="PROJECT_INFO_EDIT" v-on:click="onEdit"><i class="far fa-pen-to-square"></i></a>
      <b-popover target="PROJECT_INFO_EDIT" triggers="hover" placement="top" boundary="viewport">
        {{ $t('project.title_detail') }}
      </b-popover>
    </template>
    <template v-slot:content>
      <div class="info">
        <div class="info-row">
          <div class='info-box left' style="width: 50%;">
            <div class='info-title project'>
              Project
            </div>
            <v-clamp autoresize :max-lines="1" class="info-content">
              {{ project.name }}
            </v-clamp>
          </div>
          <div class='info-box left' style="width: 30%;">
            <div class='info-title'>Status</div>
            <div class='info-content'>
              {{ project.stage ? project.stage.name : '' }}
            </div>
          </div>
          <div class='info-box' style="width: 20%;">
            <div class='info-title'>Priority</div>
            <div class='info-content'>{{ project.priority }}
            </div>
          </div>
        </div>
        <div class='info-box'>
          <div class='info-title'>Description</div>
          <v-clamp autoresize :max-lines="3" class="info-content desc-wrap">
              {{ project.description }}
          </v-clamp>
        </div>
        <div class='info-box date'>
          <div class='date-start'>
            <div class='info-title'>Start Date</div>
            <div class='info-content'>{{ start }}</div>
          </div>
          <div class='date-remain'>
            {{ daysRemaining }}
          </div>
          <div class='date-end'>
            <div class='info-title'>Finish Date</div>
            <div class='info-content'>{{ end }}</div>
          </div>
          <div class='date-progress'>
            <div class='chart-bar'>
              <div class='chart-bar-progress' :style="{ width: progress+'%', backgroundColor: 'var(--status-orange)' }"></div>
            </div>
          </div>
        </div>
        <div class='info-row'>
          <div class='info-box left' style="width: 50%;">
            <div class='info-title'>Customer</div>
            <v-clamp autoresize :max-lines="2" class="info-content">
              {{ customer }}
            </v-clamp>
          </div>
          <div class='info-box' style="width: 50%;">
            <div class='info-title'>Rebates</div>
            <v-clamp autoresize :max-lines="2" class="info-content">
              {{ rebate }}
            </v-clamp>
          </div>
        </div>
        <div class='info-row'>
          <div class='info-box left' style="width: 50%;">
            <div class='info-title'>Work Completed By</div>
            <v-clamp autoresize :max-lines="2" class="info-content">
              {{ company }}
            </v-clamp>
          </div>
          <div class='info-box' style="width: 50%;">
            <div class='info-title'>Location</div>
            <v-clamp autoresize :max-lines="2" class="info-content">
              {{ location }}
            </v-clamp>
          </div>
          
        </div>
      </div>
    </template>
  </WidgetFrame>
</template>

<script>
  import * as moment from 'moment-timezone';
  moment.tz.setDefault('Etc/UTC');
  import VClamp from 'vue-clamp'
  import WidgetFrame from "@/components/Dashboard/WidgetFrame";
  import { toFixed } from '@/helpers/task-duration-process';

  export default {
    name: 'ProjectInfoWidget',
    components: {
      WidgetFrame,
      VClamp
    },
    props: {
      project:      { type: Object, required: true }
    },
    computed: {
      sizeClass: function () {
        let size = {};
        if (this.width < 500) {
          size['SM'] = true;
        }
        return size;
      },
      start() {
        return this.project.scheduleStart
          ? moment(this.project.scheduleStart).format("Do MMM, YYYY")
          : "No Start";
      },
      end() {
        return this.project.scheduleFinish
          ? moment(this.project.scheduleFinish).format("Do MMM, YYYY")
          : "No End";
      },
      customer() {
        return this.project.customerList
          ? this.project.customerList.map(c => c.name).join(", ")
          : "No Customer";
      },
      location() {
        return this.project.locationList
          ? this.project.locationList.map(c => c.name).join(", ")
          : "No Location";
      },
      company() {
        return this.project.companyList
          ? this.project.companyList.map(c => c.name).join(", ")
          : "No Companies";
      },
      rebate() {
        return this.project.rebateList
          ? this.project.rebateList.map(c => c.name + " ("+ toFixed(c.rebate*100, 2) +"%)").join(", ")
          : "No Rebates";
      },
      daysRemaining() {
        if (!this.project.scheduleStart || !this.project.scheduleFinish) {
          return '';
        }
        const now = moment().startOf('day');
        const start = moment(this.project.scheduleStart).startOf('day');
        const end = moment(this.project.scheduleFinish).startOf('day');
        const diff = end.diff(Math.max(now, start), 'days');
        if (diff <= 0) {
          return "No days remaining";
        }
        return diff + " days remaining";
      },
      progress() {
        if (!this.project.scheduleStart || !this.project.scheduleFinish) {
          return 0;
        }
        const now = moment().startOf('day');
        const start = moment(this.project.scheduleStart).startOf('day');
        const end = moment(this.project.scheduleFinish).startOf('day');

        const fullDays = end.diff(start, 'days');
        const currentDays = now.diff(start, 'days');
        return Math.min(100, (currentDays / fullDays) * 100);
      }
    },
    data() {
      return {
        width: null,
        height: null,
      };
    },
    methods: {
      onResize({width, height}) {
        this.width = width;
        this.height = height;
      },
      onEdit() {
        this.$emit("editProject", this.project.uuId);
      }
    }
  }
</script>

<style lang="scss" scoped>
.content {
  overflow: overlay;
}
.info {
  display: flex;
  height: 100%;
  flex-wrap: wrap;
  overflow: auto;
}

.info > div {
  flex: 1 1 100%;
}

.two-thirds {
  display: flex;
}

.info-box.left {
  margin-right: 8px;
}

.info-box {
  border: 1px solid var(--border-medium);
  padding: 10px 20px;
  border-radius: 4px;
  margin: 4px 0px;
}

.info-title {
  font-weight: 700;
}

.info-title.project .edit {
  float: right;
  cursor: pointer;
}

.desc-wrap {
  white-space: pre-line;
}

/* Date row */
.info-box.date {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
}
.date-end {
  text-align: right;
}
.date-progress {
  width: 100%;
}
.date-remain {
  align-self: flex-end;
}

.info-row {
  display: flex;
  justify-content: space-between;
}

/* Responsive styles */
.SM .info-box {
  padding: 8px 10px;
}

.SM .info-box.date {
  position: relative;
}

.SM .date-remain {
  position: absolute;
  bottom: 40px;
  text-align: center;
  width: 93%;
  color: var(--text-dark);
}
.SM .date-progress {
  margin-top: 30px;
}

.SM .info-box {
  margin: 2px 0px;
}

.SM .info-row {
  flex-direction: column;
}
.SM .info-row div {
  width: 100% !important;
}
</style>